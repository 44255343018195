@import '../../variables';
@import '../../placeholders';

.navBar {
  @extend %flex-center;
  justify-content: space-between;
  width: 100vw;
  position: absolute;
  padding: 22px;
  font-family: $body-font;
  color: $grey;
  border-radius: 0 0 25px 25px;

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    font-size: 24px;
  }

  .iconContainer:hover {
    opacity: 1;
    cursor: pointer
  }

  .menuIcon {
    font-size: 28px;
    color: $black;
    opacity: 0.4;
  }

  .menuIcon:hover {
    opacity: 1;
    cursor: pointer;
  }

  .menu {
    a {
      color: $grey;
      font-size: 16px;
      text-decoration: none;
      padding: 0 6px;
      margin: 3px 7px;
      border-radius: 6px;
    }

    a:hover {
      color: $black;
    } 
  }

  .active {
    color: $pink;
  }
  
  .default {
    color: $black;
    font-size: 24px;
    opacity: 0.4;
  }

  .default:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.open {
  background-color: $white;
}

@media only screen and (min-width: 1020px) {
  .navBar {
    .menu {
      display: flex;
      justify-content: space-evenly;
      width: 60%;
    }
  }
}