@import '../../variables';
@import '../../placeholders';

.projectCard {
  display: flex;
  background-color: $white;
  border-radius: 10px;
  margin: 30px auto;
  border: 1px solid $white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  max-width: 450px;

  .left {
    display: flex;
    flex-direction: column;
    width: 81%;
    margin: 10px 0 8px 10px;

    h3 {
      font-size: 28px;
      font-weight: 400;
      text-align: left;
      padding: 5px;
      margin: 10px 0 5px;
    }

    p {
      text-transform: none;
      text-align: left;
      padding: 5px;
      margin: 10px 0 5px;
      line-height: 1.3;
      font-size: 18px;
    }

    hr {
      border: none;
      height: 1px;
      background: linear-gradient( to right, rgba(195, 245, 132, 1), rgba(0, 0, 0, 0));
    }

    img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .iconContainer {
    @extend %flex-center;
    flex-direction: column;
    justify-content: space-evenly;
    width: 20%;

    .skillsIcon {
      font-size: 24px;
    }

    .linkIcon {
      font-size: 24px;
      color: $pink;
    }
  }
}

@media only screen and (min-width: 600px) {
  .projectCard {
    .iconContainer {
      .skillsIcon,
      .linkIcon {
        font-size: 33px;
      }
    }
  }
}

@media only screen and (min-width: 1020px) {
  .projectCard {
    width: 30%;
    margin: 50px;

    .iconContainer {
      .skillsIcon,
      .linkIcon {
        font-size: 24px;
      }
    }
  }
}