@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Raleway&display=swap');

%heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}