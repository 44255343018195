@import '../../variables';
@import '../../placeholders';

.projectsContainer {
  @extend %flex-center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: $orange-light;
  padding: 80px 0 30px;

  .headingContainer {
    width: 100%;
    
    hr {
      border: none;
      height: 1px;
      background: linear-gradient( to right, rgba(165, 150, 150, 0), rgb(255, 255, 255), rgba(165, 150, 150, 0));
    }
  }

  .projectCardsContainer {
    width: 90%;
    margin: 10px 0;
  }
}

@media only screen and (min-width: 600px) {
  .projectsContainer {
    .projectCardsContainer {
      width: 60%;
      margin: 10px 0;
    }
  }
}

@media only screen and (min-width: 1020px) {
  .projectsContainer {
    padding-top: 100px;
    
    .projectCardsContainer {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin: 20px 0;
    }
  }
}