@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Raleway&display=swap');

$body-font: 'Raleway', sans-serif;

$orange-light: #FFD271;
$orange-dark: #f8ad0b;
$pink: #F65C78;
$green: #C3F584;
$black: #1b1b1b;
$grey: #757575;
$grey-light: #a5a4a4;
$white: #f9f9f9;