@import '../../variables';
@import '../../placeholders';

.homeContainer {
  @extend %flex-center;
  flex-direction: column;
  height: 100vh;

  h1 {
    @extend %heading;
    font-size: 64px;
    margin: 40px 0 10px;
  
    .name {
      font-size: 96px;
    }
  }
  
  h2 {
    @extend %heading;
    font-size: 24px;
    color: $orange-dark;
    margin: 20px;
  }
  
  .linkedinIcon, 
  .githubIcon {
    margin: 15px;
    font-size: 50px;
  }
  
  .githubIcon {
    color: $pink;
  }
  
  .linkedinIcon {
    color: $green;
  }
  
}