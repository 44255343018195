* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: lowercase;
}

.App {
  overflow-x: hidden;
}