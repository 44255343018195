@import '../../variables';
@import '../../placeholders';

.contactContainer {
  @extend %flex-center;
  height: 100vh;
  flex-direction: column;

  hr {
    border: none;
    height: 1px;
    background: linear-gradient( to right, rgba(187, 178, 178, 0), rgb(246, 92, 120), rgba(187, 178, 178, 0));
    width: 80%;
    margin: 0 auto;
  }

  p {
    font-size: 18px;
    margin: 30px auto;
    width: 70%;
  }

  .iconContainer {
    @extend %flex-center;
    flex-direction: column;
    justify-content: space-between;

    .linkedinIcon,
    .githubIcon {
      font-size: 50px;
      color: $orange-light;
      margin: 10px;
    }

    .mailIconContainer {
      @extend %flex-center;
      width: 45px;
      height: 45px;
      background-color: $orange-light;
      border-radius: 4px;
      margin: 10px;

      a {
        @extend %flex-center;
        
        .mailIcon {
          font-size: 38px;
          color: $white;
        }
      }
    }
  } 
}
